import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/referentiel';
const reference_engin = 'ref_engins';

export default {
    getReferentialEngin(): Promise<AxiosResponse<IServerResponse<[]>>> {
        return axios.get(`${resource}/${reference_engin}`);
    },
}